<template>
  <div>

    <div class="platform-buttom-list">
      <div
        :class="tagType===1? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(1)"
        v-if="isAuth('StoreVaue_Contract')"
      >储值合同</div>
      <div
        :class="tagType===2? 'bottom-button-active':'bottom-button'"
        v-if="isAuth('StoreVaue_Detail')"
        @click="handleChangeTag(2)"
      >明细列表</div>
    </div>

    <component :is="currentComponent"></component>

  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    ContractList: () => import('./ContractList.vue'),
    StoredValue: () => import('./StoredValue.vue')
  },
  data () {
    return {
      tagType: 1,
      currentComponent: 'ContractList'
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.tagType = tag
      switch (this.tagType) {
        case 1:
          this.currentComponent = 'ContractList'
          this.setShowRightBlock(false)
          this.setRightComponent('')
          break
        case 2:
          this.currentComponent = 'StoredValue'
          this.setShowRightBlock(true)
          this.setRightComponent('storedSummary')
          break
      }
    }
  }
}
</script>
